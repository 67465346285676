import React, { useState } from 'react'
import { Logo } from '../pages'
import helpIcon from '../assets/icons/help.svg'
import profilePicture from '../assets/images/profile-picture.png'
import menu from '../assets/icons/menu.svg'
import cross from '../assets/icons/cross.svg'
import LeftPanel from './leftPanel'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const { auth } = useSelector(state => state);
    return (
        <>
            <div id='action-panel-header'>
                <div>
                    <div id='menu-icon-wrapper' onClick={e => setShowMenu(true)}>
                        <img src={menu} alt="menu" />
                    </div>
                    <Logo classname={'brand-logo'} size={{ img: '48px', brandName: '26px' }} />
                </div>
                <div>
                    <div className='help-btn'>
                        <button onClick={() => window.open("https://paybrise.app/contact-us", '_blank', 'noopener,noreferrer')}>
                            <img src={helpIcon} alt="help-btn" width={24} height={24} />Help
                        </button>
                    </div>
                    <div>
                        <span>{auth.user?.email}</span>
                        <div className='profile-pricture-wrapper'>
                            <img src={auth?.avatar || profilePicture} alt="user-profile" width={50} height={50} />
                        </div>
                    </div>
                    
                </div>
            </div>
            <div id='menu-wrapper' className={showMenu ? 'active' : ''}>
                <div id='menu-close'>
                    <img src={cross} alt="cross" onClick={e => setShowMenu(false)} />
                </div>
                <LeftPanel />
            </div>
        </>
    )
}

export default Header