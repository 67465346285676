import React, { useEffect, useState } from 'react'
import { Cards, Pagination } from '../components/index'
import support from '../assets/icons/vector.svg'
import cross from '../assets/icons/cross.svg'
import { Foundation } from '../layouts/index'
import DataTable from 'react-data-table-component'
import httpClient from '../components/httpClient'
import { useDispatch } from 'react-redux'
import { errorMessage, successMessage } from '../store/slices/messageSlice'

const TransactionFilter = ({ selected, setSelected, options }) => {
    return (
        <div id='setting-options'>
            <div className="linear-options settings">
                {options?.map(i => <button
                    className={selected === options.indexOf(i) ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => (options.indexOf(i) <= 3) && setSelected(options.indexOf(i))}
                >{i}</button>)}
            </div>
        </div>
    )
}

const Layout = () => {


    const [activePopup, setActivePopup] = useState(null)

    const [transactions, setTransactions] = useState();

    const [selected, setSelected] = useState(0);

    const [filterInput, setFilterInput] = useState("");

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await httpClient.getApi('/transactions');
                setTransactions(response.data.data);
            } catch (e) {
                console.log(e);
            }
        };
        loadData();
    }, []);

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    const openPopup = (orderId) => {
        setActivePopup(orderId);
    }

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilterInput(value);
    };


    const filterData = () => {
        const pageFilter = () => {
            return transactions.filter((e) => {
                if (selected === 0) return true;
                if (selected === 1) return e.status === 'success';
                if (selected === 2) return e.status === 'failed';
                if (selected === 3) return e.status === 'pending';
            });
        }

        const pageFilterOutput = pageFilter();

        if (filterInput && filterInput !== "") {
            return pageFilterOutput.filter((e) => {
                if (e.txHash) {
                    return e.txHash.includes(filterInput);
                }
                return true;
            });
        }

        return pageFilterOutput;
    }


    if (!transactions) return (
        <>
            <h5>Please Wait...</h5>
        </>
    )

    return (
        <div id="transactions">
            <div id="your-business">
                <Cards transactions={transactions} heading={'Transactions Overview'} />
            </div>
            <div id="table">

                <div>
                    <table>
                        <tr>
                            <td><TransactionFilter selected={selected} setSelected={setSelected} options={[`All (${transactions.length})`, `Success`, `Fail`, `Pending`]} /></td>
                            <td>
                                <div id='user-auth-form'>
                                    <input
                                        value={filterInput}
                                        onChange={handleFilterChange}
                                        placeholder={"Search Trasnaction Hash"}
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>

                    <div className='line l1' />
                    <div className='line l2' />
                    <DataTable
                        data={filterData()}
                        title='Transactions'
                        pagination
                        noDataComponent={(
                            <>
                                <div>
                                    <b>
                                        No Transactions Found....
                                    </b>
                                </div>
                            </>
                        )}
                        columns={[
                            {
                                name: 'Time',
                                selector: row => convertDate(Date.parse(row['createdAt'])),
                                sortable: true,
                            },

                            {
                                name: 'Id',
                                selector: row => row.orderId,
                                sortable: true,
                            },
                            {
                                name: 'Transaction Hash',
                                selector: row => row.txHash,
                                sortable: true,
                            },
                            {
                                name: 'Amount',
                                selector: row => row.amount,
                                sortable: true,
                            },
                            {
                                name: 'Source',
                                selector: row => row.source,
                                sortable: true,
                            },
                            {
                                name: 'Commission',
                                selector: row => row.adminCut,
                                sortable: true,
                            },
                            {
                                name: 'Status',
                                selector: row => {
                                    return (<div className={`table-btn`} ><button style={{ background: row.status === 'pending' ? '#F6B048' : row.status === 'success' ? 'green' : 'red' }}>{row.status.toUpperCase()}</button></div>)
                                },
                            },
                            {
                                name: 'Support',
                                selector: row => (
                                    <div onClick={e => openPopup(row.orderId)}>
                                        <img src={support} alt="support" width={32} height={32} />
                                    </div>
                                )
                            }
                        ]}
                    />
                    {activePopup && <TicketInfoPopup orderId={activePopup} setActivePopup={setActivePopup} />}
                </div>

                {/* <Pagination /> */}
            </div>
        </div>
    )
}

const TicketInfoPopup = ({ orderId, basicinformation, setActivePopup, whiteHeader = true, setInfoUpdate }) => {
    const [ticket, setTicket] = useState();
    const [ticketExists, setTicketExists] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({
        titleError: '',
        descError: '',
    });
    const dispatch = useDispatch();


    useEffect(() => {
        const checkTicket = async () => {
            try {
                setLoading(true);
                const response = await httpClient.getApi(`/support/${orderId}`);
                setLoading(false);
                if (response.data.data) {
                    setTicketExists(true);
                    setTicket(response.data.data);
                    setTitle(response.data.data.title);
                    setDescription(response.data.data.description);
                    return;
                }
                setTicket(false);
            } catch (e) {
                setLoading(false);
                setActivePopup();
            }
        }
        checkTicket();
    }, []);

    const handleSubmit = () => {

        if (title === null) {
            setError({ titleError: 'Title is required' });
            return;
        }

        if (description === null) {
            setError({ descError: 'Description is required' });
            return;
        }

        const dataInput = {
            orderId,
            title,
            description
        }

        if (ticketExists && !loading) {
            updateSupportTicket(dataInput);
        } else {
            createSupportTicket(dataInput);
        }
    }

    const createSupportTicket = async (inputData) => {
        try {
            setLoading(true);
            const response = await httpClient.postApi(`/support`, inputData);
            dispatch(successMessage('New Ticket created!!'));
            setTicket(response.data.data);
            setTitle(response.data.data.title);
            setDescription(response.data.data.description);
            setLoading(false);
        } catch (e) {
            dispatch(errorMessage('Failed to create ticket!!!'));
            console.log(e);
            setLoading(false);
        }
    }

    const updateSupportTicket = async (inputData) => {
        try {
            setLoading(true);
            await httpClient.patchApi(`/support/${orderId}`, inputData);
            dispatch(successMessage('Ticket details updated!!!'));
            setLoading(false);
        } catch (e) {
            dispatch(errorMessage('Failed to update ticket details!!!'));
            console.log(e);
            setLoading(false);
        }
    }

    const form = (
        <div className='default-theme-input'>
            <label htmlFor="ticket-title">Subject</label>
            <input type="text" id='ticket-title' onChange={(e) => setTitle(e.target.value)} value={title} style={{ fontWeight: 'bold' }} />
            {error.titleError && <span className='text-danger'>{error.titleError}</span>}

            <label htmlFor="ticket-description">Description</label>
            <textarea cols='50' rows='10' id='ticket-description' onChange={(e) => setDescription(e.target.value)} value={description} style={{ fontWeight: 'bold' }} />
            {error.descError && <span className='text-danger'>{error.descError}</span>}

            {ticket && ticket.reply ? (
                <>
                    <label htmlFor="ticket-reply">Reply</label>
                    <div id='ticket-reply'>
                        <b>
                            {ticket.reply}
                        </b>
                    </div>
                </>
            ) : <></>}

        </div>
    );

    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head ${whiteHeader ? 'white' : ''}`}>Create Support Ticket</div>
                <div className='popup-content'>
                    <div style={{ fontWeight: 'bold' }} id="upload-contract">
                        {form}
                    </div>
                </div>
                <div className='popup-btns'>
                    <button className='default-theme-btn upper' onClick={() => setActivePopup()}>Cancel</button>
                    <button className='default-theme-btn upper' onClick={() => handleSubmit()}>
                        {loading ? 'Please Wait...' : ticketExists ? 'Update' : 'Create'}
                    </button>
                </div>
            </div>
        </div>
    )
}

const Transactions = () => <Foundation Layout={Layout} />
export default Transactions