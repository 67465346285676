import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const options = [
    "Business Details",
    "Contract",
    // "Documents Verification",
    "Settlement wallet"
]
const SettingOptions = () => {
    
    const Navigate = useNavigate()
    const [selected, setSelected] = useState(options.find(i => window.location.pathname.includes(i.toLowerCase().replace(' ', '-'))))
    const onClick = i => {
        Navigate(`/setting/${i.toLowerCase().replace(' ', '-')}`)
        setSelected(i)
    }

    return (
        <div id='setting-options'>
            <h2>Setting</h2>
            <div className="linear-options settings">
                {options?.map(i => <button
                    className={selected === i ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClick(i)}
                >{i}</button>)}
            </div>
        </div>
    )
}

export default SettingOptions