import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import LoginImage from '../../assets/images/login.png'
import { AuthLayout } from '../../layouts'
import authService from '../../services/authService';
import { useDispatch, useSelector } from 'react-redux';
import { verification } from '../../store/slices/authSlice';
const VerifiedUser = () => <AuthLayout
	imgsrc={LoginImage}
	Heading={"Please Wait Your Account Checking For Verification"}
	Type={"auth_verified"}
/>

export const VerifiedUserForm = () => {

	const [code, setCode] = useState();
	const [loading, setLoading] = useState(true);
	const { message } = useSelector((state) => state.message);
	const [successful, setSuccessful] = useState(false);
	const { tokenId } = useParams();
	const dispatch = useDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		if (!tokenId) {
			navigate('/login');
			return;
		}

		dispatch(verification(tokenId))
			.unwrap()
			.then((data) => {
				navigate('/login');
				setSuccessful(true);
				setLoading(false);
				return;
			})
			.catch((err) => {
				setSuccessful(false);
				setLoading(false);
			});

	}, [tokenId]);


	return (
		<form id='user-auth-form'>
			<div className='message'>
				<div>Thanks for keeping your account secure.</div>
				<div></div>
			</div>
			<h5>
				Account is verified please
			</h5>
			<a href='/login' >Click here to login</a>


		</form>
	)
}

export default VerifiedUser