import React, { useState } from 'react'
import { Cards, Pagination, TableFilters } from '../components/index'
import { Foundation } from '../layouts/index'

const tableData = [
    {
        "Date": "12-09-22",
        "Transaction Hash": "Abchash",
        "Source": "Custom Websit",
        "Amount": "00000567BRISE",
        "commission": "00000002",
        "Status": "Settle Now",
    },
    {
        "Date": "12-09-22",
        "Transaction Hash": "Abchash",
        "Source": "Custom Websit",
        "Amount": "00000567BRISE",
        "commission": "00000002",
        "Status": "Pending",
    },
    {
        "Date": "12-09-22",
        "Transaction Hash": "Abchash",
        "Source": "Custom Websit",
        "Amount": "00000567BRISE",
        "commission": "00000002",
        "Status": "Settled",
    }
]
const settlementCategories = [
    "All Settlement",
    "Settled",
    "Pending"
]

const Layout = () => {
    const [selectedCategory, setSelectedCategory] = useState('0')
    return (
        <div id="settlements">
            <div id="your-business">
                <Cards heading={'Settlement Overview'} />
            </div>

            <div className='linear-options'>
                {settlementCategories?.map((category, i) =>
                    <button
                        id={`${i}`}
                        className={selectedCategory === `${i}` ? 'selected' : ''}
                        onClick={e => setSelectedCategory(e.target.id)}
                    >{category}
                    </button>
                )}
            </div>

            <div id="table">

                <div>
                    <TableFilters />
                    <div className='line l1' />
                    <div className='line l2' />
                    <div id='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            Date
                                        </div>
                                    </th>
                                    <th>Transaction Hash</th>
                                    <th>Source</th>
                                    <th>Amount</th>
                                    <th>commission</th>
                                    <th>Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableData.map(i => (
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                {i["Date"]}
                                            </div>
                                        </td>
                                        <td>{i["Transaction Hash"]}</td>
                                        <td>{i["Source"]}</td>
                                        <td>{i["Amount"]}</td>
                                        <td>{i["commission"]}</td>
                                        <td className={`table-btn ${i["Status"]?.toLowerCase()?.replace(' ', '-')}`}><button>{i["Status"]}</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                <Pagination />
            </div>
        </div>
    )
}

const Settlements = () => <Foundation Layout={Layout} />

export default Settlements