import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactComponent as Dashboard } from '../assets/icons/monitor.svg'
import { ReactComponent as Transactions } from '../assets/icons/creditcard.svg'
import { ReactComponent as Settlements } from '../assets/icons/handshake.svg'
import { ReactComponent as API } from '../assets/icons/table.svg'
import { ReactComponent as Setting } from '../assets/icons/nut.svg'

const labels = ['Dashboard', 'Transactions', 'API', 'Setting', 'Logout']
const Icon = ({ label, fill }) => {
    switch (label) {
        case 'Dashboard': return <Dashboard fill={fill} />
        case 'Transactions': return <Transactions fill={fill} />
        case 'Settlements': return <Settlements fill={fill} />
        case 'API': return <API fill={fill} />
        case 'apiKey': return <API fill={fill} />
        case 'Setting': return <Setting fill={fill} />
        default: break;
    }
}

const LeftPanel = () => {
    
    const Navigate = useNavigate()
    const [selected, setSelected] = useState(labels.find(i => window.location.pathname.includes(i.toLowerCase())))
    const onClick = l => {
        setSelected(l)
        if(l.toLowerCase() === 'logout') {
            localStorage.clear();
            sessionStorage.clear();
            return Navigate('/login');
        }
        if(l.toLowerCase() === 'api') return Navigate('/apiKey');
        Navigate(`/${l.toLowerCase()}`)
    }
    return (
        <div id='action-panel-leftside'>
            {labels.map(l => (
                <div className={selected === l ? 'selected' : ''} style={{ cursor: 'pointer' }} onClick={e => onClick(l)}>
                    <Icon label={l} fill={selected === l ? 'white' : 'url(#brise-theme)'} />
                    <span>{l}</span>
                </div>
            ))}
        </div>
    )
}

export default LeftPanel