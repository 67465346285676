import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import { store } from './store';
import './styles/index.css';
import { Provider } from 'react-redux';
import Alert from './components/Alert';

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3/dist/web3.min";

import { ConnetionProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root'));

function getLibrary(provider) {
  return new Web3(provider);
}

root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ConnetionProvider>
        <Provider store={store}>
          <Alert />
          <Router>
            <App />
          </Router>
        </Provider>
      </ConnetionProvider>
    </Web3ReactProvider>

  </React.StrictMode>
);