import React, { useState } from 'react'
import { Foundation } from '../../layouts/index'
import dustbin from '../../assets/icons/dustbin.svg'
import { POPUP, UploadContent } from '../../components/index'
const tableData = [
    {
        "Date": "12-09-22",
        "Name": "WordPress",
        "Size": "89MB",
    }
]

const Layout = () => {
    const [activePopup, setActivePopup] = useState(null)
    return (
        <div id='contract'>
            <div>
                <div id="table">
                    <div className="heading-row">
                        <span className='capital'>Uploded Contract File</span>
                        <button className="default-theme-btn upper" onClick={() => setActivePopup(true)}>Upload Contract</button>
                    </div>

                    <div className='line l1' />
                    <div className='line l2' />

                    <div id='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            Date
                                        </div>
                                    </th>
                                    <th>Name</th>
                                    <th>Size</th>
                                    <th></th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableData.map(i => (
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                {i["Date"]}
                                            </div>
                                        </td>
                                        <td style={{ fontWeight: '700' }}>{i["Name"]}</td>
                                        <td style={{ fontWeight: '700' }}>{i["Size"]}</td>
                                        <td className='table-btn default-theme'><button style={{ fontWeight: '600', fontSize: '13px' }}>download</button></td>
                                        <td><img src={dustbin} alt="delete" width={32} height={32} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {activePopup && <POPUP
                btns={["Upload", "cancel"]}
                Content={UploadContent}
                heading={"Upload Contract"}
                close={() => setActivePopup(null)}
            />}
        </div>
    )
}

const Contract = () => <Foundation Layout={Layout} />
export default Contract