import React, { useState } from 'react'
import dustbin from '../assets/icons/dustbin.svg'
import folder from '../assets/icons/folder.svg'
import upload from '../assets/icons/upload.svg'
import dropdown from '../assets/icons/dropdown.svg'
import Options from './options'
import DragDropFile from './DragDropFile'

const documentTypes = ["Government-Issued ID Card", "Passport", "Driver's License", "Bank statement", "Identity Document ", "bissiness licence ", "income tax licence ",]
const UploadContent = ({ askDocumentType }) => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [transactionType,setTransactionType] = useState('');
    const [infomationstore,setInformationstore] = useState({
        name: '',
        size: 0,
        type: '',
        url: ''
    });
    
    return (
        <div style={{ fontWeight: 'bold' }} id="upload-contract">
            {/* {askDocumentType && <div className='default-theme-input'>
                <label htmlFor="api-label">Select Your Document</label>
                <div className='dropdown'>
                    <div
                        id='dropdown-label'
                        className='dropdown-select'
                        onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                    ><span>Transactions hash</span> <img src={dropdown} alt="dropdown" /></div>
                    {activeDropdown && <Options data={documentTypes} bg={'#FDFEFF'} />}
                </div>
            </div>} */}
            <label htmlFor="api-label">Select Your Document</label>
            <select className='dropdown' onChange={(e)=>setTransactionType(e.target.value)}>
                    <option value="">Transactions hash</option>
                {documentTypes.map((i,j)=>(
                    <option value={i}>{i}</option>  
                ))}
            </select>
            
            <p>To add a new Contract, please upload and drag your file here.</p>
            {/* <div id='file-upload' >
                <img src={upload} alt="upload" width={64} height={64} />
                <span>Upload & Drag</span>
            </div> */}
            <DragDropFile setInformationstore={setInformationstore}/>
           
        </div>
    )
}

export default UploadContent