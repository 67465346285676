import React, { useState } from 'react'
import { Foundation } from '../../layouts/index'
import threedots from '../../assets/icons/threedots.svg'
import { Options, POPUP } from '../../components'
import SettlementWalletPopup from '../../components/SettlementWalletPopup'
import { useDispatch } from 'react-redux'
import { BounceLetterLoader } from 'react-spinner-overlay'
import httpClient from '../../components/httpClient'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'
import { useEffect } from 'react'
import Switch from "react-switch";
import Swal from 'sweetalert2';

import useConnection from '../../context';
import PAYBRISE_ABI from "../../abi/abi.json";
import keccak256 from "keccak256";

const AddAddress = () => (
    <div className='default-theme-input'>
        <label htmlFor="api-label">Add Address</label>
        <input type="text" id='api-label' style={{ fontWeight: 'bold', width: '30vw' }} />
    </div>
)

const Layout = () => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [activePopup, setActivePopup] = useState(null)
    const [infoUpdate, setInfoUpdate] = useState(null)
    const [kycData, setKycData] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    const { connect, account, library, isActive, contractAddress } = useConnection();

    if (infoUpdate) {
        userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    }

    const fetchData = async () => {
        try {

            const url = '/merchant/' + userinfo.merchant._id + '/wallet';
            const response = await httpClient.getApi(url);
            setKycData(response.data.data);
            setLoading(false);
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'));
            setLoading(false);

        }
    }

    useEffect(() => {
        fetchData();
    }, [dispatch, infoUpdate]);


    const updateRecord = async (editData) => {
        try {
            const infoSave = {
                address: editData.address,
                status: (editData.status === 'active') ? 'inactive' : 'active'
            }

            const url = '/merchant/' + editData.merchant + '/wallet/' + editData._id;
            const response = await httpClient.patchApi(url, infoSave);
            fetchData();
            setLoading(false);
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'));
            setLoading(false);

        }
    }

    const handleDelete = async (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                rundelete(data);

            }
        })
    }

    const rundelete = async (data) => {
        try {


            const url = '/merchant/' + data.merchant + '/wallet/' + data._id;
            const response = await httpClient.deleteApi(url);

            setLoading(false);
            dispatch(successMessage('Wallet Details Deleted successfully'))
            fetchData();
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'))

        }
    }

    const setDefaultAddress = async (editData) => {
        try {

            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .changePayoutAddress(keccak256(userinfo.merchant._id), editData.address)
                .send({ from: account })
                .then(async (result) => {
                    const infoSave = {
                        address: editData.address,
                        status: 'active'
                    }
                    const url = '/merchant/' + editData.merchant + '/wallet/' + editData._id;
                    const response = await httpClient.patchApi(url, infoSave);
                    fetchData();
                    setLoading(false);
                    dispatch(successMessage('default address change successfully'))
                });
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'));
            setLoading(false);

        }
    }

    const datePrint = (ls) => {
        var todayDate = new Date(ls).toISOString().slice(0, 10);
        return todayDate;
    }
    return (
        <div id='documents-verification'>
            <div>
                <div id="table">
                    <div className="heading-row">
                        <span className='capital'>Settlement wallet address</span>
                        <button className="default-theme-btn upper" onClick={e => setActivePopup(true)}>+add Address</button>
                    </div>

                    <div className='line l1' />
                    <div className='line l2' />

                    <div id='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            Date
                                        </div>
                                    </th>
                                    <th>Address</th>
                                    {/* <th>Settled Amount</th> */}
                                    <th>status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loading ? <BounceLetterLoader loading={loading} color="#FF7626" /> : ''}
                                {kycData?.length > 0 && kycData?.map((i, n) => (
                                    <tr key={n}>
                                        <td style={{ fontWeight: '400' }}>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                {datePrint(i["createdAt"])}
                                            </div>
                                        </td>
                                        <td style={{ fontWeight: '700' }}>{i["address"]}</td>
                                        {/* <td style={{ fontWeight: '700' }}>1230</td> */}
                                        <td style={{ fontWeight: '700' }}>
                                            {
                                                i['status'] === "active" ?
                                                    "Default"
                                                    :
                                                    <button
                                                        className='default-theme-btn'
                                                        onClick={() => setDefaultAddress(i)}
                                                    >set Default</button>
                                            }
                                        </td>
                                        <td style={{ position: 'relative' }}>
                                            <img src={threedots} alt='options' width={25} height={25} onClick={e => setActiveDropdown(d => d === n ? null : n)} />
                                            {activeDropdown === n && <div className='dropdown-options-wrapper' style={{ top: "15px", width: "auto", left: "-140px" }} >
                                                <div className='dropdown-options' style={{ background: '#EFF3F9' }}>
                                                    <div className='' onClick={() => handleDelete(i)}>Delete</div>
                                                    <div className=''>
                                                        <Switch onChange={() => updateRecord(i)} checked={(i['status'] == 'active') ? true : false} />
                                                    </div>
                                                </div></div>}
                                        </td>
                                    </tr>

                                ))}
                            </tbody>


                        </table>
                    </div>
                </div>
            </div>
            {activePopup && <SettlementWalletPopup setActivePopup={setActivePopup} setInfoUpdate={setInfoUpdate} basicinformation={userinfo} />}
        </div>
    )
}

const SettlementWallet = () => <Foundation Layout={Layout} />
export default SettlementWallet