import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../../components/httpClient';
import authService from '../../services/authService';
import { errorMessage,  successMessage } from './messageSlice';

const tokenInfo = JSON.parse(localStorage.getItem('jt_user_info'));

export const mercentInfo = createAsyncThunk('basicinfo',async(post,thunkAPI)  =>{
    
    try{
        const data = await httpClient.getApi('/user/profile');
        
        localStorage.setItem('jt_user_info',JSON.stringify(data.data.data));
        return thunkAPI.fulfillWithValue({user:data.data.data});
    }catch(error)
    {
        
        return thunkAPI.rejectWithValue();
    }
});

export const register = createAsyncThunk('user/create',async(postData,thunkAPI)=>{

    try{
        const data = await authService.register(postData);
        thunkAPI.dispatch(successMessage('Account verification email has been sent to your email!'));
        return thunkAPI.fulfillWithValue({user:data.data.data});
    }catch(error)
    {
        thunkAPI.dispatch(errorMessage("Account with same Email already exists"));
        return thunkAPI.rejectWithValue();
    }

});

export const verification = createAsyncThunk('auth/verify',async(token,thunkAPI)=>{
    try{
        const data = await authService.verification(token);
        thunkAPI.dispatch(successMessage('Account is Verified Please Login!'));
        return thunkAPI.fulfillWithValue({user:data.data.data});
    }catch(error)
    {
        thunkAPI.dispatch(errorMessage('Something Went Wrong!'));
        return thunkAPI.rejectWithValue();
    }
});

export const login = createAsyncThunk('login',async(postData,thunkAPI)=>{

    try{
        const data = await authService.login(postData);
        thunkAPI.dispatch(successMessage('Login Success'));
        return {user:data.data}
    }catch(error)
    {
        
        thunkAPI.dispatch(errorMessage(error.response.data.message));
        return thunkAPI.rejectWithValue();
    }
});
export const refreshtoken = createAsyncThunk('refreshtoken',async(testdata,thunkAPI)=>{
    const jt_refresh_token = localStorage.getItem("jt_refresh_token");
    if(jt_refresh_token)
    {
        try{
            const postData = {refresh:jt_refresh_token};
            const jsonTrim = JSON.stringify(postData);
           
            const data = await authService.refreshToken(jsonTrim);
            thunkAPI.dispatch(successMessage('Logged In'));
            localStorage.setItem('jt_access_token',data.data.data.access.token);
            localStorage.setItem('jt_refresh_token',data.data.data.refresh.token);
            localStorage.setItem('jt_user_info',JSON.stringify(data.data.data));
            return thunkAPI.fulfillWithValue({user:data.data.data});
        }catch(error)
        {
          
            thunkAPI.dispatch(errorMessage('Login Expire, Please Login Again!'));
            return thunkAPI.rejectWithValue();
        }
    }else{
        thunkAPI.dispatch(errorMessage(''));
            return thunkAPI.rejectWithValue();
    }
   
});



export const logout = createAsyncThunk('auth/logout',async()=>{
    await authService.logout();
}); 



const initialState = tokenInfo ? {isLoggedIn:true,user:tokenInfo}:{isLoggedIn:false,user:null};



const authSlice = createSlice({
    name:'auth',
    initialState,
    extraReducers:{
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
        [login.fulfilled]:(state,action)=>{
            state.isLoggedIn = true;
            state.user = action.payload.user.data;
        },
        [login.rejected]:(state,action)=>{
            state.isLoggedIn = false;
            state.user = null;
        },
        [mercentInfo.fulfilled]:(state,action)=>{
            state.user = action.payload.user.data;
        },
        [mercentInfo.rejected]:(state,action)=>{
            state.user = null;
        },
        [refreshtoken.fulfilled]:(state,action)=>{
            state.isLoggedIn = true;
            state.user = action.payload.user.data;
        },
        [refreshtoken.rejected]:(state,action)=>{
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]:(state,action)=>{
            state.isLoggedIn = false;
            state.user = null;
        },
        [verification.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
        },
        [verification.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
    }
});


const { reducer } = authSlice;
export default reducer;