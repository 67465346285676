// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto'
import { Bar, Doughnut } from "react-chartjs-2";

Chart.defaults.plugins.legend.position = 'bottom';
Chart.defaults.plugins.legend.labels.pointStyle = 'circle';
Chart.defaults.plugins.legend.labels.usePointStyle = true;
Chart.defaults.plugins.legend.labels.padding = 20;
Chart.defaults.plugins.legend.labels.pointStyleWidth = 20;

export const BarChart = ({ rawData }) => {
    const processedData = [];
    rawData.forEach((e, index) => {
        let [success, pending, fail] = [0, 0, 0];
        e.orders.forEach((i) => {
            if (i.status === 'success') success++;
            if (i.status === 'pending') pending++;
            if (i.status === 'fail') fail++;
        });
        processedData.push({
            id: index,
            date: e._id,
            successful: success,
            pending: pending,
            fail: fail,
        });
    });
    const barChartData = {
        labels: processedData.map(i => i.date),
        datasets: [
            {
                label: "successful",
                data: processedData.map(i => i.successful),
                backgroundColor: ["#00A939"]
            },
            {
                label: "pending",
                data: processedData.map(i => i.pending),
                backgroundColor: ["#BBBCCB"]
            },
            {
                label: "fail",
                data: processedData.map(i => i.fail),
                backgroundColor: ["#F64848"]
            },
        ],
    }
    return (<Bar data={barChartData} />)
};

export const DoughnutChart = ({ rawData })  => {
    const [labels, data] = [[], []];
    rawData.forEach((e) => {
        labels.push(e._id);
        data.push(e.count);
    });
    const doughnutChartData = {
        labels,
        datasets: [
            {
                label: "Transactions",
                data,
                backgroundColor: [
                    "#00A939",
                    "#BBBCCB",
                    "#F64848",
                ]
            }
        ],
    }
    return (<Doughnut data={doughnutChartData} />);
};