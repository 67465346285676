import React, { useEffect, useState } from 'react'
import { BarChart, Cards, DoughnutChart, Pagination } from '../components/index'
import checksqr from '../assets/icons/checksqr.svg'
import dropdown from '../assets/icons/dropdown.svg'
import searchIcon from '../assets/icons/search.svg'
import dashboardTopRight from '../assets/images/dashboardtopright.png'
import { Foundation } from '../layouts/index'
import httpClient from '../components/httpClient'
import DataTable from 'react-data-table-component'

const Layout = () => {

    const [transactions, setTransactions] = useState();

    const [uiData, setUidDate] = useState();

    useEffect(() => {
        const loadData = async () => {
            const response = await httpClient.getApi('/transactions');
            setTransactions(response.data.data);
        };
        const loadGraphData = async () => {
            const response = await httpClient.getApi('/transactions/overview');
            setUidDate(response.data.data);
        };
        loadGraphData();
        loadData();
    }, []);

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    if (!transactions) return (
        <>
            <h5>Please Wait...</h5>
        </>
    )

    return (
        <div className='dashboard'>

            <div id="middle">
                <div id="your-business">
                    <Cards heading={'Your Business'} transactions={transactions} />
                    {uiData && uiData.barChartData.length > 0 ? <div ><BarChart rawData={uiData.barChartData} /></div> : <></>}
                </div>

                <div id="table">
                    <div>
                        {/* <div id='top'>
                            <p>Recent Transactions</p>
                            <p>Successful</p>
                            <p>Pending</p>
                            <p>Failed</p>
                        </div>
                        <div id='options'>
                            <div className='dropdown'>
                                <div>15 <img src={dropdown} alt="dropdown" /></div>
                            </div>

                            <div id='searchbar-container'>
                                <input type="text" className='input-default-theme' placeholder='Search' />
                                <div id='searchIcon'><img src={searchIcon} alt="" srcset="" width={24} height={24} /></div>
                            </div>
                        </div> */}

                        {/* <div className='line l1' />
                        <div className='line l2' /> */}
                        <DataTable
                            data={transactions}
                            title='Transactions'
                            pagination
                            noDataComponent={(
                                <>
                                    <div>
                                        <b>
                                            No Transactions Found....
                                        </b>
                                    </div>
                                </>
                            )}
                            columns={[
                                {
                                    name: 'Time',
                                    selector: row => convertDate(Date.parse(row['createdAt'])),
                                    sortable: true,
                                },
                                {
                                    name: 'Transaction Hash',
                                    selector: row => row.txHash,
                                    sortable: true,
                                },
                                {
                                    name: 'Amount',
                                    selector: row => row.amount,
                                    sortable: true,
                                },
                                {
                                    name: 'Status',
                                    selector: row => {
                                        return (<div className={`table-btn`} ><button style={{ background: row.status === 'pending' ? '#F6B048' : row.status === 'success' ? 'green' : 'red' }}>{row.status.toUpperCase()}</button></div>)
                                    },
                                }
                            ]}
                        />

                    </div>
                    {/* <Pagination /> */}
                </div>
            </div>

            <div id="right">
                <Offer />
                <div id='pie-charts-container'>
                    {uiData && uiData.donutData.length > 0 ? <div className="pie-chart" style={{ width: "250", height: "250" }}>
                        <h2>Monthly transactions</h2>
                        <div className='pie-chart-wrapper'><DoughnutChart rawData={uiData.donutData} /></div>
                    </div> : <></>}

                    {/* <div className="pie-chart" style={{ width: "250", height: "250" }}>
                        <h2>Settlement</h2>
                        <div className='pie-chart-wrapper'><DoughnutChart /></div>
                    </div>
                    <div className="pie-chart" style={{ width: "250", height: "250" }}>
                        <h2>Sources</h2>
                        <div className='pie-chart-wrapper'><DoughnutChart /></div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const Dashboard = () => <Foundation Layout={Layout} />

const Offer = () => {
    return (
        <div id='offer'>
            <h2>Meet your cash flow needs with Priority Settlements</h2>
            <div id='reasons'>
                <div>
                    <img src={checksqr} alt="check" width={25} />
                    <span>Early access to settlements</span>
                </div>
                <div>
                    <img src={checksqr} alt="check" width={25} />
                    <span>Full control on your cashflow</span>
                </div>
            </div>
            <div id='big-image'><img src={dashboardTopRight} alt="dashboard top right" /></div>
            {/* <div id='buttons'>
                <button>I'm interested</button>
                <button>Know more</button>
            </div> */}
        </div>
    )
}

export default Dashboard