import React from 'react'
import prev from '../assets/icons/prev.svg'
import back from '../assets/icons/back.svg'
import next from '../assets/icons/next.svg'
import skip from '../assets/icons/skip.svg'

const Pagination = () => {
    return (
        <div id='paginatoin-wrapper'>
            <span>Showing 1 to 9 of 9 entries</span>
            <div>
                <div className={'page-actions'}><img src={prev} alt="prev" /></div>
                <div className={'page-actions'}><img src={back} alt="back" /></div>
                {["1", "2", "3", "4", "5", "..", "10"]?.map(i => <span>{i}</span>)}
                <div className={'page-actions'}><img src={next} alt="next" /></div>
                <div className={'page-actions'}><img src={skip} alt="skip" /></div>
            </div>
        </div>
    )
}

export default Pagination