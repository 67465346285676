import React from 'react'

const Options = ({ data, bg = "#EFF3F9", style, showActiveSwitch }) => (
    <div className='dropdown-options-wrapper' style={style}>
        <div className='dropdown-options' style={{ background: bg }}>
            {data.map(i => <div>
                {i}
                {i === 'Activate' && showActiveSwitch &&
                    <div id='switch-wrapper'><div id='switch-circle' /></div>
                }
            </div>)}
        </div>
    </div>
)

export default Options