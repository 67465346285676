import httpClient from "../components/httpClient"

const login = async (data) =>{
  return  httpClient.postApi('/user/authenticate',data);
}

const register =  (data) =>{

        const response =  httpClient.postApi('user/create',data);
        return response;
}


const verification = (token) =>{
    return httpClient.getApi('/user/verify/'+token);
}
const refreshToken = async (data) =>{
    return await httpClient.postApi('/user/refresh-token',data);
}

const logout = () =>{
     localStorage.removeItem('user');
}

const authService = {
    verification,
    refreshToken,
    login,
    logout,
    register,
    
}

export default authService;