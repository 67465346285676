import React, { useEffect, useState } from 'react'
import { AuthLayout } from '../../layouts/index'
import imagesrc from '../../assets/images/signup.jpeg'
import eyeIcon from '../../assets/icons/eye.svg'
import dropDownIcon from '../../assets/icons/dropdown.svg'
import { useDispatch, useSelector } from 'react-redux'
import { clearMessage } from '../../store/slices/messageSlice'
import { register } from '../../store/slices/authSlice'
import { useNavigate } from 'react-router-dom'
//import { useSelector } from 'react-redux';  // importing useSelector does

const SignUp = () => <AuthLayout
  imgsrc={imagesrc}
  Heading={"Create your Paybrise account "}
  Type={"signup"}
/>

export const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState('CREATE ACCOUNT');
  const [input, setInput] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    isWebsite: false,
    monthlyExpectedSales: "$5000+",
  });

  const [error, setError] = useState({
    email: '',
    password: '',
    confirmPassword: '',

  })




  const handleSubmit = (e) => {
    console.log(input);
    e.preventDefault();
    setIsDisabled(true);
    setIsProcessing('Please Wait...');
    const dataInput = {
      isWebsitePayment: input.isWebsite === 'true' ? true : false,
      password: input.password,
      email: input.email,
      monthlyExpectedSales: input.monthlyExpectedSales,
      firstName: '',
      lastName: '',
      country: ''
    }
    const inputResponse = JSON.stringify(dataInput);
    dispatch(register(inputResponse)).unwrap()
      .then((data) => {

        navigate('/login');
      }).
      catch((err) => {
        setIsDisabled(false);
        setIsProcessing('CREATE ACCOUNT');
      })
  }


  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email Address.";
          }
          break;

        case "password":
          let check = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (value.length < 8) {
            stateObj[name] = "Password must be 8 characters";
          } else if (!value.match(check)) {
            stateObj[name] = "Password must be strong characters";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }


  return (
    <form id='user-auth-form' onSubmit={handleSubmit}>
      <label htmlFor="email">Email Address</label>
      <input
        type="text"
        id='email'
        name='email'

        value={input.email}
        onChange={onInputChange}
        onBlur={validateInput}
        required
      />
      {error.email && <span className='signup_err'>{error.email}</span>}

      <label htmlFor="password">
        <span>Password</span>
        <div className='label-icon' onClick={() => setShowPassword(!showPassword)}>
          <img src={eyeIcon} alt="show password" />
        </div>
      </label>
      <input
        id='password'
        type={showPassword ? "text" : "password"}
        name="password"
        value={input.password}
        onChange={onInputChange}
        onBlur={validateInput}
        required
      />
      {error.password && <span className='signup_err'>{error.password}</span>}

      <label htmlFor="confirm-password">
        <span>Confirm Password</span>
        <div className='label-icon' onClick={() => setShowPassword(!showPassword)}>
          <img src={eyeIcon} alt="show confirm password" />
        </div>
      </label>
      <input
        id='confirm-password'
        type={showPassword ? "text" : "password"}
        name="confirmPassword"
        value={input.confirmPassword}
        onChange={onInputChange}
        onBlur={validateInput}
        required
      />
      {error.confirmPassword && <span className='signup_err'>{error.confirmPassword}</span>}


      <label>Do you want to collect payments on your website</label>
      <div id='collect-on-web'>
        <div>
          <input type="radio" name="isWebsite" id="collect-on-web-y" value={true} onChange={onInputChange} />
          <label htmlFor="collect-on-web-y">Yes</label>
        </div>

        <div>
          <input type="radio" name='isWebsite' id="collect-on-web-n" value={false} onChange={onInputChange} />
          <label htmlFor="collect-on-web-n">No</label>
        </div>
      </div>

      {input.isWebsite === 'true' ? <input
        id='website-url'
        type="text"
        placeholder={'Enter your website URL'}
        required={input.isWebsite === 'true' ? true : false}
      /> : <></>}

      <label htmlFor="monthly-expected-sales">
        <span>Monthly expected sales</span>
        
      </label>
      <select
        required
        name="monthlyExpectedSales"
        className='sales-dropdown'
        id="monthly-expected-sales"
        placeholder="Monthly expected sales" onChange={onInputChange} >
        <option value="$5000+">$5000+</option>
        <option value="$25000+">$25000+</option>
        <option value="$100000+">$100000+</option>
        <option value="$500000+">$500000+</option>
      </select>
      <button >{isProcessing}</button>

    </form>
  )
}

export default SignUp