import React, { useEffect, useState } from 'react'
import { Options, Pagination, TableFilters } from '../components/index'
import copy from '../assets/icons/copy.svg'
import threedots from '../assets/icons/threedots.svg'
import { Foundation } from '../layouts/index'
import { useDispatch } from 'react-redux'
import httpClient from '../components/httpClient'
import { errorMessage, successMessage } from '../store/slices/messageSlice'
import { BounceLetterLoader } from 'react-spinner-overlay'
import Swal from 'sweetalert2'
import Switch from 'react-switch'


import useConnection from '../context';
import PAYBRISE_ABI from "../abi/abi.json"
import keccak256 from "keccak256";

const POPUP = ({ close, setInfoUpdate }) => {

    const [keyCreated, setKeyCreated] = useState(false);
    const [keyData, setKeyData] = useState({});
    const [copyText, setCopyText] = useState(null);
    const [copyTextButton, setCopyTextButton] = useState('Copy to clipdoard');

    const dispatch = useDispatch();
    const [label, setLabel] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState(null);
    const [callbackUrl, setCallbackUrl] = useState(null);
    const [error, setError] = useState({
        labelError: '',
        sourceError: ''
    });

    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));

    const handleSubmit = () => {

        let errorStatus = false;

        if (label === null) {
            setError({ labelError: 'Label is required' });
            return;
        }
        if (source === null) {
            setError({ sourceError: 'Source is required' });
            return;
        }
        if (callbackUrl === null) {
            setError({ sourceError: 'Source is required' });
            return;
        }

        const dataInput = {
            label: label,
            source: source,
            callbackUrl: callbackUrl,
            status: "inactive"
        }

        handleSubmitData(dataInput);
    }

    const handleSubmitData = async (inputData) => {

        setIsDisabled(true);
        setLoading(true)

        try {
            const response = await httpClient.postApi('/merchant/' + userinfo.merchant._id + '/apiKey', inputData);
            setLoading(false);
            setIsDisabled(false);
            setKeyData(response.data.data);
            const text = 'Api key=' + response.data.data.key + '<br>' + 'Api Secret=' + response.data.data.secretKey;
            setCopyText(text);
            dispatch(successMessage('New Api Key Created'))
            // setActivePopup();
            setInfoUpdate(true);
            setKeyCreated(true);

        } catch (e) {
            dispatch(errorMessage('Something Went Wrong!'))
            setLoading(false);
            setIsDisabled(false);
        }


        setLoading(false);
    }

    const clickToCopy = () => {
        navigator.clipboard.writeText(copyText)
        setCopyTextButton('Copied Successfully');
    }
    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head white`}>Create new API authentication credentials</div>
                <div className='popup-content' style={{ fontWeight: 'bold' }}>
                    <p>Give the API key label so you can identify it later.</p>
                    <div className='default-theme-input'>
                        <label htmlFor="api-label">API key label</label>
                        <input type="text" id='api-label' onChange={(e) => setLabel(e.target.value)} value={label} style={{ fontWeight: 'bold' }} />
                        {error.labelError && <span className='text-danger'>{error.labelError}</span>}
                    </div>
                    <div className='default-theme-input'>
                        <label htmlFor="api-label">API key Source</label>
                        <input type="text" id='api-label' onChange={(e) => setSource(e.target.value)} value={source} style={{ fontWeight: 'bold' }} />
                        {error.sourceError && <span className='text-danger'>{error.sourceError}</span>}
                    </div>
                    <div className='default-theme-input'>
                        <label htmlFor="api-label">callbackUrl</label>
                        <input type="text" id='api-label' onChange={(e) => setCallbackUrl(e.target.value)} value={callbackUrl} style={{ fontWeight: 'bold' }} />
                        {error.sourceError && <span className='text-danger'>{error.sourceError}</span>}
                    </div>
                    {keyCreated &&
                        <>
                            <div id='api-key-details'>
                                <span>API Key = {keyData.key}</span>
                                <span>API secret = {keyData.secretKey}</span>
                            </div>

                            <p style={{ textAlign: 'center' }}><img src={copy} alt="copy-icon" onClick={() => clickToCopy()} /> {copyTextButton} </p>
                        </>
                    }
                </div>
                <div className='popup-btns'>
                    {!keyCreated ?
                        <>
                            <button className='default-theme-btn upper' onClick={close}>Cancel</button>
                            <button className='default-theme-btn upper' disabled={isDisabled} onClick={e => handleSubmit()}>{loading ? 'Please Wait' : 'Create key'}</button>
                        </> :
                        <button className='default-theme-btn upper' onClick={close}>Done</button>
                    }
                </div>
            </div>
        </div>
    )
}

const Layout = () => {

    const {
        disconnect,
        connect,
        account,
        library,
        isActive,
        contractAddress
    } = useConnection();

    const [activeDropdown, setActiveDropdown] = useState(null)
    const [activePopup, setActivePopup] = useState(null)
    const [infoUpdate, setInfoUpdate] = useState(null)


    const [kycData, setKycData] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));

    if (infoUpdate) {
        userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    }

    const fetchData = async () => {
        try {

            const url = '/merchant/' + userinfo.merchant._id + '/apiKey';
            const response = await httpClient.getApi(url);
            setKycData(response.data.data);
            setLoading(false);
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'));
            setLoading(false);

        }
    }

    useEffect(() => {
        fetchData();
    }, [dispatch, infoUpdate]);

    const updateRecord = async (editData) => {
        try {
            const infoSave = {
                source: editData.source,
                label: editData.label,
                status: (editData.status === 'active') ? 'inactive' : 'active'
            }

            const url = '/merchant/' + editData.merchant + '/apiKey/' + editData._id;
            const response = await httpClient.patchApi(url, infoSave);
            fetchData();
            setLoading(false);
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'));
            setLoading(false);

        }
    }

    const handleDelete = async (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                rundelete(data);

            }
        })

    }

    const rundelete = async (data) => {
        try {
            const url = '/merchant/' + data.merchant + '/apiKey/' + data._id;
            const response = await httpClient.deleteApi(url);

            setLoading(false);
            dispatch(successMessage('Api Details Deleted successfully'))
            fetchData();
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        } catch (e) {

            dispatch(errorMessage('Something Went Wrong'))

        }
    }

    const datePrint = (ls) => {
        var todayDate = new Date(ls).toISOString().slice(0, 10);
        return todayDate;
    }
    const displayLimit = (str) => {
        if (str.length > 10) return str.substring(0, 10) + '...';
    }

    const handleCopy = (str) => {
        navigator.clipboard.writeText(str)
        dispatch(successMessage('Copied Successfully'))
    }

    const syncContract = async (editData) => {
        setLoading(true);

        const merchantId = keccak256(editData.merchant);
        const pojectId = keccak256(editData.secretKey);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .CreateProject(merchantId, pojectId)
                .send({ from: account })
                .then(async (result) => {
                    const infoSave = {
                        source: editData.source,
                        label: editData.label,
                        status: 'active'
                    }
                    const url = '/merchant/' + editData.merchant + '/apiKey/' + editData._id;
                    await httpClient.patchApi(url, infoSave);
                    fetchData();
                    dispatch(successMessage('Api Key Successfully sync to contract'))
                    setLoading(false);
                })
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
            // notify(true, "Withdraw Stake Interest fail");
        }
    };


    return (
        <div id="api">
            <div id="table">
                <div>
                    <div className='heading-row'>
                        <span>API</span>

                        <div>
                            {isActive ?
                                <button
                                    className='default-theme-btn'
                                    style={{ marginRight: "10px" }}
                                    onClick={() => disconnect()}
                                >
                                    Disconnect
                                </button> :
                                <button
                                    className='default-theme-btn'
                                    style={{ marginRight: "10px" }}
                                    onClick={() => connect("metaMask")}
                                > Connect Wallet
                                </button>
                            }
                            <button
                                className='default-theme-btn'
                                onClick={() => setActivePopup(true)}
                            >+ Create new api
                            </button>
                        </div>
                    </div>
                    {/* <TableFilters /> */}
                    <div className='line l1' />
                    <div className='line l2' />

                    <div id='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            Date
                                        </div>
                                    </th>
                                    <th>Source</th>
                                    <th>API</th>
                                    <th>Salt</th>
                                    <th>status</th>
                                    <th>actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loading ? <BounceLetterLoader loading={loading} color="#FF7626" /> : ''}
                                {kycData?.length > 0 && kycData?.map((i, n) => (
                                    <tr key={n}>
                                        <td>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                {datePrint(i["createdAt"])}
                                            </div>
                                        </td>
                                        <td>{i["source"]}</td>
                                        <td>{displayLimit(i["key"])} <button className='withnobg' onClick={() => handleCopy(i["key"])}><img src={copy} alt="copy" /></button></td>
                                        <td>{displayLimit(i["secretKey"])} <button className='withnobg' onClick={() => handleCopy(i["secretKey"])}><img src={copy} alt="copy" /></button></td>
                                        <td>
                                            {
                                                i["status"] === "active" ?
                                                    "Active" :
                                                    <button className='default-theme-btn' disabled={loading} onClick={() => syncContract(i)}>
                                                        syncContract
                                                    </button>
                                            }
                                        </td>
                                        <td style={{ position: 'relative' }}>
                                            <img src={threedots} alt='options' width={25} height={25} onClick={e => setActiveDropdown(d => d === n ? null : n)} />
                                            {activeDropdown === n && <div className='dropdown-options-wrapper' style={{ top: "15px", width: "auto", left: "-140px" }} >
                                                <div className='dropdown-options' style={{ background: '#EFF3F9' }}>
                                                    <div className='' onClick={() => handleDelete(i)}>Delete</div>
                                                    <div className=''>
                                                        <Switch onChange={() => updateRecord(i)} checked={(i['status'] == 'active') ? true : false} />
                                                    </div>
                                                </div></div>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                {/* <Pagination /> */}
            </div>
            {activePopup && <POPUP close={() => setActivePopup(false)} setInfoUpdate={setInfoUpdate} />}
        </div >
    )
}

const API = () => <Foundation Layout={Layout} />

export default API