import React, { useState } from 'react'
import { AuthLayout } from '../../layouts/index'
import imagesrc from '../../assets/images/forgotpassword.jpeg'
import eyeIcon from '../../assets/icons/eye.svg'
import passwordResetIcon from '../../assets/images/passwordreset.jpeg'
import { useNavigate, useParams } from "react-router-dom";
import httpClient from '../../components/httpClient'
import { errorMessage,  successMessage } from '../../store/slices/messageSlice';
import { useDispatch } from 'react-redux';


const ResetPassword = () => {
    const [success, setSuccess] = useState(false)
    return <>
        {
            success
                ? <PasswordReseted />
                : <AuthLayout
                    imgsrc={imagesrc}
                    Heading={"Reset Your Password"}
                    Type={"reset-password"}
                    callback={() => setSuccess(true)}
                />
        }
    </>
}

export const ResetPasswordForm = ({ callback }) => {
    const { token } = useParams();
    const [passwords, setPasswords] = useState({})
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch();

    const handlePasswordVisibility = e => {
        const id = e.target.parentNode.parentElement.getAttribute('for')
        setShowPassword(showPassword === id ? false : id)
    }

    const onsubmit = async e => {
        e.preventDefault();
        
        try {
            setLoading(true);
            if(passwords.newPassword !== passwords.confirmPassword) {
                alert('new password and confirm password is not matching');
                return;
            }
            await httpClient.postApi('/user/verify-reset-password', { password: passwords.newPassword, token });
            setLoading(false);
            callback();
        } catch(e) {
            setLoading(false);
            console.log(e);
            dispatch(errorMessage('Failed to update password, please verify if password containts 8 or more characters with mix of letters, numbers, and symbols. '))
        }
    }

    return (
        <form id='user-auth-form' onSubmit={onsubmit}>

            <label htmlFor="password">
                <span>New Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='password'
                type={showPassword === 'password' ? "text" : "password"}
                value={passwords?.newPassword}
                onChange={e => setPasswords(curr => ({ ...curr, newPassword: e.target.value }))}
                minLength={8}
                required
            />

            <label htmlFor="confirm-password">
                <span>Confirm Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='confirm-password'
                type={showPassword === 'confirm-password' ? "text" : "password"}
                value={passwords?.confirmPassword}
                onChange={e => setPasswords(curr => ({ ...curr, confirmPassword: e.target.value }))}
                minLength={8}
                required
            />

            <p id='reset-password-message'>Use minimum 8 or more characters with mix of letters, numbers, and symbols.</p>
            <button type={"submit"}>{loading ? 'Please wait' : 'Reset' }</button>
        </form>
    )
}

export const PasswordReseted = () => {
    
    const Navigate = useNavigate()
    return <div id='password-reseted'>
        <div onClick={e => Navigate(`/login`)}>
            <div id='password-reset-img'><img src={passwordResetIcon} alt="password reseted" width={412} height={412} /></div>
            <p id='message'>Your New Password Set Successfully</p>
            <div id='shadow' />
        </div>
    </div>
}

export default ResetPassword