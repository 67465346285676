import './styles/App.css';
import './styles/layouts.css';
import './styles/pages.css';
import './styles/responsive.css';
import { Navigate, Route, Routes } from "react-router-dom";
import { LogoPage, Login, TwoStepVerification, ForgotPassword, SignUp, ResetPassword, Dashboard, Transactions, Settlements, API, BussinessDetails, Contract, DocumentsVerification, SettlementWallet } from './pages/index';
import ProtectedRoute from './components/ProtectedRoute';
import { useEffect, useState } from 'react';
import VerifiedUser from './pages/auth/VerfiedUser';
import { useDispatch, useSelector } from 'react-redux';
import {  refreshtoken } from './store/slices/authSlice';

const App = () => {
  const { auth } = useSelector(state=>state);
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   // dispatch(dispatch(refreshtoken('dfdsf'))).unwrap().then((data)=>{

  //   // }).catch((err)=>{

  //   // });
  // },[dispatch]);
 
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/two-step-verification" element={<TwoStepVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/auth/verify/:tokenId" element={<VerifiedUser />} />
        
        <Route path="/dashboard" element={
          <ProtectedRoute auth={auth} >
            <Dashboard />
          </ProtectedRoute>
        } />
        <Route path="/transactions" element={
          <ProtectedRoute auth={auth} >
            <Transactions />
          </ProtectedRoute>
        } />

        <Route path="/settlements" element={
          <ProtectedRoute auth={auth} >
            <Settlements />
          </ProtectedRoute>
        } />

        <Route path="/apiKey" element={
          <ProtectedRoute auth={auth} >
            <API />
          </ProtectedRoute>
        } />
       <Route path="setting" element={
            <ProtectedRoute auth={auth} >
              <BussinessDetails />
            </ProtectedRoute>
          } />
          <Route path="setting/business-details" element={
            <ProtectedRoute auth={auth} >
              <BussinessDetails />
            </ProtectedRoute>
          } />
          <Route path="setting/contract" element={
            <ProtectedRoute auth={auth} >
              <Contract />
            </ProtectedRoute>
          } />

          <Route path="setting/documents-verification" element={
            <ProtectedRoute auth={auth} >
              <DocumentsVerification />
            </ProtectedRoute>
          } />

          <Route path="setting/settlement-wallet" element={
            <ProtectedRoute auth={auth} >
              <SettlementWallet />
            </ProtectedRoute>
          } />
       

      </Routes>
    </div>
  );
}

// <Route path="/transactions" element={<Transactions />} />
// <Route path="/settlements" element={<Settlements />} />
// <Route path="/api" element={<API />} />

// <Route path="/setting" element={<Navigate to={"/setting/business-details"} />} />
// <Route path="/setting/business-details" element={<BussinessDetails />} />
// <Route path="/setting/contract" element={<Contract />} />
// <Route path="/setting/documents-verification" element={<DocumentsVerification />} />
// <Route path="/setting/settlement-wallet" element={<SettlementWallet />} />






export default App;
