
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DragDropFile from '../../components/DragDropFile'
import httpClient from '../../components/httpClient'
import kycService from '../../services/kycService'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'
//import { useSelector } from 'react-redux';  // importing useSelector does


const TestComponent = () => {
    const [x, setX] = useState("hello");  // useState works correctly
    console.log(useSelector);  // but useSelector is undefined when the application runs
    return <p> {x} </p>
}


const documentTypes = ["Government-Issued ID Card", "Passport", "Driver's License", "Bank statement", "Identity Document ", "bissiness licence ", "income tax licence ",]
const DocumentVerificationPopup = ({ setActivePopup,whiteHeader = true , setInfoUpdate }) => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transactionType,setTransactionType] = useState('');
    
    const userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    
    const dispatch = useDispatch();
    const [error,setError] = useState({
        selectBox:'',
        fileInput:''
    });
    const [infomationstore,setInformationstore] = useState({
        name: '',
        size: 0,
        type: '',
        url: ''
    });
  
    const handleSubmit = () =>{
       
      if(transactionType == '' && infomationstore.name == '')
      {
        setError({
        selectBox:'Please Select Option',
        fileInput:'Please Upload File '

        });
        return ;
      }
      if(transactionType == '' && infomationstore.name == '')
      {
        setError({
        selectBox:'Please Select Option',
        fileInput:'Please Upload File '

        });
        return ;
      }
      if(transactionType == '')
      {
        setError({
        selectBox:'Please Select Option',
        fileInput:''
        });
        return ;
      }
      if(infomationstore.url == '')
      {
        setError({
        selectBox:'',
        fileInput:'Please Upload File '
        });
        return ;
      }
      const dataInput = {
        document:transactionType,
        file:infomationstore.url,
        size:infomationstore.size
      }

      handleSubmitData(dataInput);
    }

    const handleSubmitData =async (inputData) =>{
        
        setIsDisabled(true);
        setLoading(true)
       

        try {

            const url = '/merchant/'+userinfo.merchant._id+'/kyc';
            const response =  httpClient.postApi(url,inputData);
            
           
            setLoading(false);
            setIsDisabled(false);
            dispatch(successMessage('Kyc Created Successfully'))
            setActivePopup();
            setInfoUpdate(true);

        } catch (e) {
        
            dispatch(errorMessage('File not uploaded!'))
            setLoading(false);
            setIsDisabled(false);
        }


        setLoading(false);
    }
    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head ${whiteHeader ? 'white' : ''}`}>Upload Contract</div>
                <div className='popup-content'>
                <div style={{ fontWeight: 'bold' }} id="upload-contract">
            {/* {askDocumentType && <div className='default-theme-input'>
                <label htmlFor="api-label">Select Your Document</label>
                <div className='dropdown'>
                    <div
                        id='dropdown-label'
                        className='dropdown-select'
                        onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                    ><span>Transactions hash</span> <img src={dropdown} alt="dropdown" /></div>
                    {activeDropdown && <Options data={documentTypes} bg={'#FDFEFF'} />}
                </div>
            </div>} */}
            <label htmlFor="api-label">Select Your Document</label>
            <select className='dropdown' onChange={(e)=>setTransactionType(e.target.value)}>
                    <option value="">Transactions hash</option>
                {documentTypes.map((i,j)=>(
                    <option value={i}>{i}</option>  
                ))}
            </select>
            {error.selectBox && <span className='text-danger'>{error.selectBox}</span>}
            <p>To add a new Contract, please upload and drag your file here.</p>
            {/* <div id='file-upload' >
                <img src={upload} alt="upload" width={64} height={64} />
                <span>Upload & Drag</span>
            </div> */}
            <DragDropFile setInformationstore={setInformationstore}/>
            {error.fileInput && <span className='text-danger'>{error.fileInput}</span>}
        </div>
                </div>
                <div className='popup-btns'>
                     <button className='default-theme-btn upper' onClick={handleSubmit} disabled={isDisabled}>{loading?'Please Wait':'Upload'}</button>
                     <button className='default-theme-btn upper' onClick={()=>setActivePopup()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default DocumentVerificationPopup