import React from 'react'

const POPUP = ({ btns, Content, close, heading, whiteHeader = true }) => {
    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head ${whiteHeader ? 'white' : ''}`}>{heading}</div>
                <div className='popup-content'><Content /></div>
                <div className='popup-btns'>
                    {btns?.map(i => <button className='default-theme-btn upper' onClick={close}>{i}</button>)}
                </div>
            </div>
        </div>
    )
}

export default POPUP