import React, { useState } from 'react'
import { AuthLayout } from '../../layouts/index'
import imagesrc from '../../assets/images/forgotpassword.jpeg'
import { useNavigate } from "react-router-dom";
import httpClient from '../../components/httpClient';
import { errorMessage,  successMessage } from '../../store/slices/messageSlice';
import { useDispatch } from 'react-redux';

const ForgotPassword = () => <AuthLayout
  imgsrc={imagesrc}
  Heading={"Forgot password"}
  subHeading={"Enter your email or mobile number and we will send you a password reset link."}
  Type={"forgot-password"}
/>

export const ForgotPasswordForm = () => {

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({});

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await httpClient.postApi('/user/reset-password', { email: credentials.email  });
      dispatch(successMessage("email is sent with reset password url"));
      Navigate("/login");
    } catch(e) {
      setLoading(false);
      console.log(e);
      dispatch(errorMessage('Email address is not registered with us'));
    }
  };

  return (
    <form id='user-auth-form' onSubmit={onSubmit}>
      <label htmlFor="email">Email Address</label>
      <input
        type="text"
        id='email'
        value={credentials?.email}
        onChange={e => setCredentials(curr => ({ ...curr, email: e.target.value }))}
        required
      />

      <label htmlFor="email">Please complete the challenge below</label>
      <div id="not-a-robot-container">
        <input type="checkbox" name="not-a-robot" id="not-a-robot" />
        <div><label htmlFor="not-a-robot">I'm not a robot</label></div>
      </div>
      <button type='submit'>{!loading ? 'Send reset link' : 'Please wait'}</button>
    </form>
  )
}

export default ForgotPassword