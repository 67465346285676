import React, { useEffect, useState } from 'react'
import { Foundation } from '../../layouts/index'
import dustbin from '../../assets/icons/dustbin.svg'
import { POPUP, UploadContent } from '../../components'
import DocumentVerificationPopup from './documentVerificationPopup'
import httpClient from '../../components/httpClient'
import { useDispatch } from 'react-redux'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'
import Swal from 'sweetalert2'
import { BounceLetterLoader    } from 'react-spinner-overlay'
const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
   

const Content = () => <UploadContent askDocumentType={true} />

const Layout = () => {
    const [activePopup, setActivePopup] = useState(null)
    const [loading,setLoading] = useState(true);
    const userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    const [kycData,setKycData] = useState([]);
    const [infoUpdate,setInfoUpdate] = useState(false);
    const dispatch = useDispatch();
    
   
    const fetchData = async() =>{
        try {
            
            const url = '/merchant/'+userinfo.merchant._id+'/kyc';
            const response = await  httpClient.getApi(url);
            setKycData(response.data.data);
            setLoading(false);
        } catch (e) {
        
            dispatch(errorMessage('Something Went Wrong'));
            setLoading(false);
            
        }
    }
    useEffect(()=>{
        fetchData();
    },[dispatch,infoUpdate]);
    if(infoUpdate)
    {
        fetchData();
    }
    const handleDelete = async (kycid,merchantId) =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                rundelete(kycid,merchantId);
             
            }
          })


      
    }

    const rundelete = async (kycid,merchantId) =>{
        try {

            const url = '/merchant/'+merchantId+'/kyc/'+kycid;
            const response = await  httpClient.deleteApi(url);
            dispatch(successMessage('Kyc Details Deleted successfully'))
            fetchData();
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
        } catch (e) {
        
            dispatch(errorMessage('Something Went Wrong'))
            
        }
    }

    const niceBytes =(x) =>{

        let l = 0, n = parseInt(x, 10) || 0;
      
        while(n >= 1024 && ++l){
            n = n/1024;
        }
        
        return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
      }
    return (
        <div id='documents-verification'>
            <div>
                <div id="table">
                    <div className="heading-row">
                        <span className='capital'>Uploded Documents File</span>
                        <button className="default-theme-btn upper" onClick={e => setActivePopup(true)}>Upload Documents</button>
                    </div>

                    <div className='line l1' />
                    <div className='line l2' />

                    <div id='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            Date
                                        </div>
                                    </th>
                                    <th>Name</th>
                                    <th>Size</th>
                                    <th></th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {loading?<BounceLetterLoader    loading={loading}　color="#FF7626" />:
                            <tbody>
                                {kycData.length > 0 && kycData?.map(i => (
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                <input type="checkbox" />
                                                {i["createdAt"]}
                                            </div>
                                        </td>
                                        <td style={{ fontWeight: '700' }}>{i["document"]}</td>
                                        <td style={{ fontWeight: '700' }}>{niceBytes(i["size"])}</td>
                                        <td className='table-btn default-theme'><a href={i["file"]}  className="default-theme-btn downbtn5" style={{ fontWeight: '600', fontSize: '13px' }} download target="_blank">Download</a></td>
                                        <td><button className='withnobg' onClick={()=>handleDelete(i['_id'],i['merchant'])}><img src={dustbin} alt="delete" width={32} height={32} /></button></td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                    </div>
                </div>
            </div>
            {activePopup && <> <DocumentVerificationPopup setActivePopup={setActivePopup} setInfoUpdate={setInfoUpdate} /></>
            }
        </div>
    )
}

const DocumentsVerification = () => <Foundation Layout={Layout} />
export default DocumentsVerification