import React from 'react'
import { Header, LeftPanel, SettingOptions } from '../components/index'

const Foundation = ({ Layout }) => {
    const isSetting = window.location.pathname.includes('/setting')
    return (
        <>
            <Header />
            <div class="grid-container">
                <LeftPanel />
                {!isSetting ?
                    <Layout />
                    : <div id="settings">
                        <SettingOptions />
                        <Layout />
                    </div>
                }
            </div>
        </>
    )
}

export default Foundation