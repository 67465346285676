import React from 'react'
import { ForgotPasswordForm, LoginForm, Logo, ResetPasswordForm, SignUpForm, TwoStepVerificationForm } from '../pages/index'
import checkMarkIcon from '../assets/icons/checkmark.svg'
import { useNavigate } from "react-router-dom";
import { VerifiedUserForm } from '../pages/auth/VerfiedUser';

const AuthEssentials = ({ imgsrc, Heading, subHeading, Type, callback }) => {

    let isSignUp = Type === 'signup'
    let showSignIn = isSignUp || Type === 'reset-password'

    const Navigate = useNavigate()
    const whyChooseUs = [
        "Faster payment processing system",
        "Instant payout settlements",
        "Faster transactions"
    ]

    const form = {
        'login': <LoginForm callback={callback} />,
        'two-step-verification': <TwoStepVerificationForm callback={callback} />,
        'forgot-password': <ForgotPasswordForm callback={callback} />,
        'reset-password': <ResetPasswordForm callback={callback} />,
        'signup': <SignUpForm callback={callback} />,
        'auth_verified': <VerifiedUserForm callback={callback} />,
    }

    return (
        <div id='auth-essential-layout'>
            <div id='auth-image-container'>
                {isSignUp && <Logo classname={'brand-logo'} size={{ brandName: "3rem", img: "80px" }} />}
                <img src={imgsrc} alt={'auth-login'} />

                {isSignUp ?
                    <div id='bottom-texts-container'>
                        <h3>Why choose Paybrise for payments</h3>
                        {whyChooseUs.map(i => <div><img src={checkMarkIcon} alt="checkmark" width={16} height={16} /><p>{i}</p></div>)}
                    </div>
                    :
                    <div>
                        {/* <h3>Time is now</h3> */}
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo.</p> */}
                    </div>
                }

            </div>
            <div id='auth-form-container'>
                <div>
                    <h2>{Heading}</h2>
                    <h6>{subHeading}</h6>
                </div>
                {form[Type]}
                {showSignIn ?
                    <>
                        <p>Already have an account?</p>
                        <button onClick={e => Navigate(`/login`)}>SIGN IN</button>
                    </>
                    :
                    <>
                        <p>Dont have an account with Paybrise?</p>
                        <button onClick={e => Navigate(`/signup`)}>SIGN UP</button>
                    </>
                }
            </div>
        </div>
    )
}

export default AuthEssentials