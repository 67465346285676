import { createSlice  } from '@reduxjs/toolkit';

const initialState = {
    message:'',
    type:'',
    position:'top-right'
};

const messageSlice = createSlice({
    name:'message',
    initialState,
    reducers:{
        successMessage:(state,action)=>{
            return {
                message:action.payload,
                type:'success',
                position:'top-left'
            }
        },
        errorMessage:(state,action)=>{
            return {
                message:action.payload,
                type:'error',
                position:'top-left'
            }
        },
        clearMessage:(state,action)=>{
            return {
                message:'',
                type:''
            }
        },
    },
});

const { reducer , actions} = messageSlice ;
export const {successMessage,errorMessage,clearMessage} = actions;
export default reducer;