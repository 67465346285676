import React, { useState, useEffect, useMemo, useCallback } from "react";
import { injected, walletConnect } from "./connector";
import { useWeb3React } from "@web3-react/core";
import Swal from "sweetalert2";

export const ConnectionContext = React.createContext(null);

export const ConnetionProvider = ({ children }) => {
  const { activate, account, library, active, deactivate, chainId } = useWeb3React();

  const [isActive, setIsActive] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [shouldDisable, setShouldDisable] = useState(false); // Should disable connect button while connecting to MetaMask
  const [isLoading, setIsLoading] = useState(true);

  const contractAddress = "0x407F14C4A45c260C02B7963eBb92D3808330903E";

  // Init Loading
  useEffect(() => {
    async function fetchData() {
      var providerType = await sessionStorage.getItem("providerType");
      var isConnected = await sessionStorage.getItem("isConnected");
      if (isConnected) {
        connect(providerType).then((val) => {
          setIsLoading(false);
        });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check when App is Connected or Disconnected to MetaMask
  const handleIsActive = useCallback(() => {
    setIsActive(active);
  }, [active]);

  const handleWalletModal = async (state) => {
    setWalletModal(state);
  };

  useEffect(() => {
    handleIsActive();
  }, [handleIsActive]);

  // Connect to MetaMask wallet
  const connect = async (providerType) => {
    setShouldDisable(true);
    try {
      if (providerType === "metaMask") {
        await activate(injected).then(() => {
          sessionStorage.setItem("providerType", "metaMask");
          sessionStorage.setItem("isConnected", true);
          setShouldDisable(false);
          handleWalletModal(false);
        });
      } else if (providerType === "walletConnect") {
        await activate(walletConnect).then(() => {
          sessionStorage.setItem("providerType", "walletConnect");
          sessionStorage.setItem("isConnected", true);
          setShouldDisable(false);
          handleWalletModal(false);
        });
      } else {
      }
      setWalletModal(false);
      if (!chainId || (chainId && chainId !== 32520)) {
        if (!chainId || (chainId && chainId !== 64668)) {
          Swal.fire({
            position: 'top-end',
            icon: 'info',
            toast: true,
            text: 'swith to brise network on metamask',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
    } catch (error) {
      console.log("Error on connecting: ", error);
      setShouldDisable(false);
    }
  };

  // Disconnect from Metamask wallet
  const disconnect = async () => {
    try {
      await deactivate();
      await sessionStorage.removeItem("isConnected");
      await sessionStorage.removeItem("providerType");
      window.location.reload();
    } catch (error) {
      console.log("Error on disconnnect: ", error);
    }
  };

  const values = useMemo(
    () => ({
      isActive,
      account,
      chainId,
      isLoading,
      walletModal,
      handleWalletModal,
      connect,
      disconnect,
      library,
      shouldDisable,
      contractAddress
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, isLoading, shouldDisable, account, walletModal, chainId]
  );

  return (
    <ConnectionContext.Provider value={values}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default function useConnection() {
  const context = React.useContext(ConnectionContext);

  if (context === undefined) {
    throw new Error(
      "useMetaMask hook must be used with a ConnetionProvider component"
    );
  }

  return context;
}
