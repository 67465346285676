import React, { useEffect, useState } from 'react'
import { Foundation } from '../../layouts/index'
import profilePicture from '../../assets/images/profile-picture-large.png'
import profiledefault from '../../assets/images/profile-default.png'
import { POPUP } from '../../components'
import UpdateProfile from './updateProfile'
import { useDispatch, useSelector } from 'react-redux'
import { mercentInfo } from '../../store/slices/authSlice'
import httpClient from '../../components/httpClient'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'
//import { useSelector } from 'react-redux';  // importing useSelector does

const TestComponent = () => {
    const [x, setX] = useState("hello");  // useState works correctly
    console.log(useSelector);  // but useSelector is undefined when the application runs
    return <p> {x} </p>
}

const BusinessInfoContent = ({ setActivePopup, setApiCallWithUserFetch }) => {
    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    const [businessName, setBusinessName] = useState((userinfo.merchant.displayName === 'string') ? null : userinfo.merchant.displayName);
    const [businessType, setBusinessType] = useState((userinfo.merchant.businessType === 'string') ? null : userinfo.merchant.businessType);
    const [address, setAddress] = useState((userinfo.merchant.address === 'string') ? null : userinfo.merchant.address);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [website, setWebsite] = useState((userinfo.merchant.website === 'string') ? null : userinfo.merchant.website);


    const dispatch = useDispatch();
    const [error, setError] = useState({
        businessNameError: '',
        businessTypeError: '',
        addressError: '',
        websiteError: ''
    });

    const handleSubmit = () => {
        console.log(businessName)
        if (businessName == null) {
            setError({
                businessNameError: 'Business Name is required ',
            });
            return;
        }
        if (businessType == null) {
            setError({
                businessTypeError: 'Business Type is required ',
            });
            return;
        }
        if (address == null) {
            setError({
                addressError: 'Address is required ',
            });
            return;
        }
        if (website == null) {
            setError({
                websiteError: 'Website is required '
            });
            return;
        }
        const dataInput = {
            displayName: businessName,
            businessType: businessType,
            website: website,
            address: address,
        }

        handleSubmitData(dataInput);
    }

    const handleSubmitData = async (inputData) => {

        setIsDisabled(true);
        setLoading(true)


        try {

            const url = '/merchant/' + userinfo.merchant._id;
            const response = httpClient.patchApi(url, inputData);


            setLoading(false);
            setIsDisabled(false);
            dispatch(successMessage('Business Details Updated Successfully'))

            setActivePopup();
            setApiCallWithUserFetch(true);

        } catch (e) {
            console.log('e', e)
            dispatch(errorMessage('Something Went Wrong!'))
            setLoading(false);
            setIsDisabled(false);
        }


        setLoading(false);
    }

    return (<>
        <div className='popup-wrapper'>
            <div className='popup-container'>

                <div className={`popup-head white`}>Update Profile Information</div>
                <div className='popup-content'>
                    <div style={{ fontWeight: 'bold' }} id="upload-contract"></div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Display Name</label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setBusinessName(e.target.value)} value={businessName} />
                            {error.businessNameError && <span className='text-danger'>{error.businessNameError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Business Type</label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setBusinessType(e.target.value)} value={businessType} />
                            {error.businessTypeError && <span className='text-danger'>{error.businessTypeError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Business Address</label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setAddress(e.target.value)} value={address} />
                            {error.addressError && <span className='text-danger'>{error.addressError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Business Website </label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setWebsite(e.target.value)} value={website} />
                            {error.websiteError && <span className='text-danger'>{error.websiteError}</span>}
                        </div>
                    </div>
                </div>
                <div className='popup-btns'>
                    <button className='default-theme-btn upper' onClick={handleSubmit} disabled={isDisabled}>{loading ? 'Please Wait' : 'Submit'}</button>
                    <button className='default-theme-btn upper' onClick={() => setActivePopup()}>Cancel</button>
                </div>
            </div>

        </div></>);

}


const ContactInfoContent = ({ email }) => {
    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    return (
        <div style={{ fontWeight: 'bold' }}>
            <div className='default-theme-input' style={{ width: '30vw' }}>
                <label htmlFor="api-label">Email ID</label>
                <input type="text" id='api-label' style={{ fontWeight: 'bold' }} defaultValue={userinfo?.email} />
            </div>
        </div>
    );
}

const Layout = () => {
    const [activePopup, setActivePopup] = useState(null);

    const [apiCall, setApiCall] = useState(false);
    const [apiCallWithUserFetch, setApiCallWithUserFetch] = useState(false);

    const dispatch = useDispatch();

    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));

    if (apiCall) {
        userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    }

    const fetchCurrentUser = async () => {
        try {
            const response = await httpClient.getApi('/user/profile');
            localStorage.setItem('jt_user_info', JSON.stringify(response.data.data))
            setApiCall(true)
        } catch (e) {
            dispatch(errorMessage('Something Went Wrong!'));
        }
    }

    if (apiCallWithUserFetch) {
        fetchCurrentUser();
    }
    return (
        <>
            <div id='business-details'>
                <div id='profile'>
                    <div className='profile-pricture-wrapper' style={{ width: '180px', height: '180px' }}>

                        <img src={(userinfo?.avatar) ? (userinfo?.avatar) : profiledefault} alt="user-profile" width={164} height={164} />
                    </div>
                    <div id='profile-detail'>
                        <span>{userinfo?.firstName} {userinfo?.lastName}</span>
                        <span>{userinfo?.email}</span>
                    </div>
                    <button className="default-theme-btn upper" onClick={e => setActivePopup('profile-info')}>Upload New</button>
                </div>
                <div>
                    <div className='details'>
                        <div className="heading-row">
                            <span className='capital'>Business Information</span>
                            <button className="default-theme-btn upper" onClick={e => setActivePopup('business-info')}>Edit Now</button>
                        </div>
                        <div id='detail-data'>
                            <div>
                                <div id="key">Display Name</div>
                                <div id="value">{userinfo.merchant.displayName}</div>
                            </div>
                            <div>
                                <div id="key">Business Type</div>
                                <div id="value">{userinfo.merchant.businessType}</div>
                            </div>
                            <div>
                                <div id="key">Business Address</div>
                                <div id="value">{userinfo.merchant.address}</div>
                            </div>
                            <div>
                                <div id="key">Business Website / App Details</div>
                                <div id="value">
                                    <p>Website: {userinfo.merchant.website}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='details'>
                        <div className="heading-row">
                            <span className='capital'>Contact information</span>
                            <button className="default-theme-btn upper" onClick={e => setActivePopup('contact-info')}>Edit Now</button>
                        </div>
                        <div id='detail-data'>
                            <div>
                                <div id="key">Email ID</div>
                                <div id="value">{userinfo?.email}</div>
                            </div>

                            {/* <div>
                                <div id="key">Phone Number</div>
                                <div id="value">+91 9511906919</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {activePopup === 'business-info' && <BusinessInfoContent setActivePopup={setActivePopup} setApiCallWithUserFetch={setApiCallWithUserFetch} />}
            {activePopup === 'profile-info' && <UpdateProfile basicinformation={userinfo} setActivePopup={setActivePopup} setApiCall={setApiCall} />}
            {activePopup === 'contact-info' && <POPUP
                btns={["done", "cancel"]}
                Content={ContactInfoContent}
                heading={"Contact information "}
                close={() => setActivePopup(null)}
            />}
        </>
    )
}

const BusinessDetails = () => <Foundation Layout={Layout} />
export default BusinessDetails