
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessage, successMessage } from '../store/slices/messageSlice'
import httpClient from './httpClient'

import useConnection from '../context';
import PAYBRISE_ABI from "../abi/abi.json";
import keccak256 from "keccak256";

const documentTypes = ["Government-Issued ID Card", "Passport", "Driver's License", "Bank statement", "Identity Document ", "bissiness licence ", "income tax licence ",]
const SettlementWalletPopup = ({ basicinformation, setActivePopup, whiteHeader = true, setInfoUpdate }) => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [address, setAddress] = useState(null)

    const [loading, setLoading] = useState(false)
    const [transactionType, setTransactionType] = useState('');

    const userinfo = JSON.parse(localStorage.getItem('jt_user_info'));

    const { connect, account, library, isActive, contractAddress } = useConnection();

    const dispatch = useDispatch();
    const [error, setError] = useState({
        addressError: ''
    });

    const handleSubmit = () => {

        let errorStatus = false;

        if (address === null) {
            setError({ addressError: 'Address is required' });
            return;
        }

        const dataInput = {
            address: address,
            status: "active"
        }

        handleSubmitData(dataInput);
    }

    const handleSubmitData = async (inputData) => {

        setIsDisabled(true);
        setLoading(true)

        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .changePayoutAddress(keccak256(userinfo.merchant._id), inputData.address)
                .send({ from: account })
                .then(async (result) => {
                    const response = await httpClient.postApi('/merchant/' + userinfo.merchant._id + '/wallet', inputData);
                    setLoading(false);
                    setIsDisabled(false);
                    dispatch(successMessage('Added New Settlememt'))
                    setActivePopup();
                    setInfoUpdate(true);
                });
        } catch (e) {
            dispatch(errorMessage('Something Went Wrong!'))
            setLoading(false);
            setIsDisabled(false);
        }
        setLoading(false);
    }

    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head ${whiteHeader ? 'white' : ''}`}>Update Profile Information</div>
                <div className='popup-content'>
                    <div style={{ fontWeight: 'bold' }} id="upload-contract">

                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Address</label>
                            <input type="text" id='api-label' onChange={(e) => setAddress(e.target.value)} value={address} style={{ fontWeight: 'bold' }} />
                            {error.addressError && <span className='text-danger'>{error.addressError}</span>}
                        </div>

                    </div></div>
                <div className='popup-btns'>
                    {isActive ?
                        <button className='default-theme-btn upper' onClick={handleSubmit} disabled={isDisabled}>{loading ? 'Please Wait' : 'Submit'}</button>
                        :
                        <button className='default-theme-btn upper' onClick={() => connect("metaMask")} disabled={isDisabled}>{loading ? 'Please Wait' : 'Connect Wallet'}</button>
                    }

                    <button className='default-theme-btn upper' onClick={() => setActivePopup()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default SettlementWalletPopup;