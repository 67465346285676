
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DragDropFile from '../../components/DragDropFile'
import httpClient from '../../components/httpClient'
import kycService from '../../services/kycService'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'

const documentTypes = ["Government-Issued ID Card", "Passport", "Driver's License", "Bank statement", "Identity Document ", "bissiness licence ", "income tax licence ",]
const UpdateProfile = ({ basicinformation,setActivePopup,whiteHeader = true , setApiCall }) => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [firstName, setFirstName] = useState(basicinformation?.firstName)
    const [lastName, setLastName] = useState(basicinformation?.lastName)
    const [email, setEmail] = useState(basicinformation.email)
    
    
    
    const [loading, setLoading] = useState(false)
    const [transactionType,setTransactionType] = useState('');
    
    const userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    
    

    const dispatch = useDispatch();
    const [error,setError] = useState({
        selectBox:'',
        fileInput:''
    });
    const [infomationstore,setInformationstore] = useState({
        name: '',
        size: 0,
        type: '',
        url: basicinformation.avatar
    });
  
    const handleSubmit = () =>{
       
        let errorStatus = false;
        
      if(email === '' && firstName === ''&& lastName === '' && infomationstore.name === '')
      {
        setError({
        emailError:'Email is required',
        firstError:'First name is required ',
        lastError:'Last name is required ',
        fileInput:'Firstname is required '
        });
        return ;
      }
      if(email === '' )
      {
        setError({
            emailError:'Email is required',
        });
        return ;
      }
      if(firstName === '' )
      {
        setError({
            firstError:'Email is required',
        });
        return ;
      }
      if(lastName === '' )
      {
        setError({
            lastError:'Last name is required',
        });
        return ;
      }
    
      if(infomationstore.url === '')
      {
        setError({
        fileInput:'Please Upload File '
        });
        return ;
      }
      const dataInput = {
        firstName:firstName,
        lastName:lastName,
        email:email,
        avatar:infomationstore.url
      }
      handleSubmitData(dataInput);
    }

    const handleSubmitData =async (inputData) =>{
        
        setIsDisabled(true);
        setLoading(true)
       

        try {

            const response = await httpClient.patchApi('/user/profile',inputData);
            
            localStorage.setItem('jt_user_info',JSON.stringify(response.data.data))
            setLoading(false);
            setIsDisabled(false);
            dispatch(successMessage('Profile Updated Successfully'))
            setActivePopup();
            setApiCall(true);

        } catch (e) {
            console.log('e',e)
            // dispatch(errorMessage('Something Went Wrong!'))
            // setLoading(false);
            // setIsDisabled(false);
        }


        setLoading(false);
    }

    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head ${whiteHeader ? 'white' : ''}`}>Update Profile Information</div>
                <div className='popup-content'>
                <div style={{ fontWeight: 'bold' }} id="upload-contract">
          
        <div className='default-theme-input'>
            <label htmlFor="api-label">First Name</label>
            <input type="text" id='api-label'  onChange={(e)=>setFirstName(e.target.value)} value={firstName}  style={{ fontWeight: 'bold' }}  />
            {error.firstError && <span className='text-danger'>{error.firstError}</span>}
        </div>
        <div className='default-theme-input'>
            <label htmlFor="api-label">Last Name</label>
            <input type="text" id='api-label'  onChange={(e)=>setLastName(e.target.value)}  value={lastName} style={{ fontWeight: 'bold' }} />
            {error.lastError && <span className='text-danger'>{error.lastError}</span>}
        </div>

        <div className='default-theme-input'>
            <label htmlFor="api-label">Email</label>
            <input type="email" id='api-label' style={{ fontWeight: 'bold' }} value={email}  onChange={(e)=>setEmail(e.target.value)} />
            {error.emailError && <span className='text-danger'>{error.emailError}</span>}
        </div>
       
     
            <p>To add a new Contract, please upload and drag your file here.</p>
            
            <DragDropFile setInformationstore={setInformationstore} defaultinfo={basicinformation} />
            {error.fileInput && <span className='text-danger'>{error.fileInput}</span>}
       </div>
                </div>
                <div className='popup-btns'>
                     <button className='default-theme-btn upper' onClick={handleSubmit} disabled={isDisabled}>{loading?'Please Wait':'Upload'}</button>
                     <button className='default-theme-btn upper' onClick={()=>setActivePopup()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default UpdateProfile;