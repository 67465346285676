import React, { useState } from 'react'
import dropdown from '../assets/icons/dropdown.svg'
import searchIcon from '../assets/icons/search.svg'
import calender from '../assets/icons/calender.svg'
import archieve from '../assets/icons/archieve.svg'
import sliders from '../assets/icons/sliders.svg'
import { Options, POPUP } from '../components/index'

const options = {
    label: ["Transactions hash", "Amount", "Source", "Status"],
    timespan: ["Today", "Yesterday", "Past 7 days", "Past 30 days", "Custom Range"]
}

const TableFilters = ({ TransactionFilters }) => {

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [activePopup, setActivePopup] = useState(null);
    const Content = () => (
        <>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name='export-file-type' id="to-pdf" defaultChecked />
                <label htmlFor="to-pdf" style={{ fontWeight: 'bolder' }}>Export To PDF</label>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name='export-file-type' id='to-excel' />
                <label htmlFor="to-excel" style={{ fontWeight: 'bolder' }}>Export To EXCEL</label>
            </div>
        </>
    )

    return (
        <>
            <div id='options'>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <div className='dropdown'>
                        <div
                            id='dropdown-label'
                            className='dropdown-select'
                            onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                        >Transactions hash <img src={dropdown} alt="dropdown" /></div>
                        {activeDropdown === 'dropdown-label' && <Options data={options.label} />}
                    </div>
                    <div>
                        <div id='date-selector' className='dropdown'>
                            <div
                                id='dropdown-timespan'
                                className='dropdown-select'
                                style={{ borderRight: '1px solid #fff' }}
                                onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                            >Past 7 days <img src={dropdown} alt="dropdown" /></div>
                            <div id='calender'><img src={calender} alt="calender" /></div>
                            {activeDropdown === 'dropdown-timespan' && <Options data={options.timespan} />}
                        </div>
                    </div>
                </div>

                <div className='right-actions-container'>
                    <div id='searchbar-container'>
                        <input type="text" className='input-default-theme' placeholder='Search' style={{ width: '15rem' }} />
                        <div id='searchIcon'><img src={searchIcon} alt="" srcset="" width={24} height={24} /></div>
                    </div>
                    <div className='dropdown' onClick={() => setActivePopup(true)}><img src={archieve} alt="archieve" /></div>
                    <div className='dropdown'>
                        <img src={sliders} alt="archieve" onClick={e => setActiveDropdown(d => d === 'transactions-filters' ? null : 'transactions-filters')} />
                        {TransactionFilters && activeDropdown === 'transactions-filters' && <TransactionFilters close={e => setActiveDropdown(false)} />}
                    </div>
                </div>
            </div>
            {activePopup && <POPUP
                btns={["cancel", "export"]}
                Content={Content}
                heading={"Select Export option"}
                close={() => setActivePopup(null)}
                whiteHeader={false}
            />}
        </>
    )
}

export default TableFilters