import React from "react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({auth,children}) =>{
    
    if(!auth.isLoggedIn){
        return <Navigate to="/" replace />
    }

    return children;
}
export default ProtectedRoute;