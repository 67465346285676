import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import upload from '../assets/icons/upload.svg'
import dustbin from '../assets/icons/dustbin.svg'
import folder from '../assets/icons/folder.svg'
import uploadService from "../services/uploadService";
import { errorMessage } from "../store/slices/messageSlice";
import httpClient from "./httpClient";
import { RingSpinner   } from 'react-spinner-overlay'
// drag drop file component
const DragDropFile = ({setInformationstore,defaultinfo}) => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const [fileinfo, setFileInfo] = useState({
        name: '',
        size: 0,
        type: '',
        url: ''
    });
    // ref
    const inputRef = React.useRef(null);


    const handleFile = async (files) => {
        
       handleUploading(files);
        
       
    }
    const removeState = () =>{
        setFileInfo({
            name: '',
            size: '',
            type: '',
            url: defaultinfo.url,
        });
        setInformationstore({
            name: '',
            size: '',
            type: '',
            url:defaultinfo.url,
        });
    }
    const handleUploading =async (files) =>{
        setLoading(true)
        try {
            let formData = new FormData();
            formData.append("file", files[0]);
            const data = await httpClient.uploadApi('/media/upload', formData);
            const info = data.data.data;
            setFileInfo({
                name: info.name,
                size: info.size,
                type: info.type,
                url: info.url,
            });
            setInformationstore({
                name: info.name,
                size: info.size,
                type: info.type,
                url: info.url,
            });
            setLoading(false);

        } catch (e) {
            setFileInfo({
                name: '',
                size: 0,
                type: '',
                url: ''
            });
            errorMessage('File not uploaded!')
            setLoading(false);
        }
        setLoading(false);
    }
    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <>


{loading?<RingSpinner   loading={loading}　color="#FF7626" />:
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} className="d-none" />
                <div id='file-upload' >
                    <button className="upload-button withnobg" onClick={onButtonClick}><img src={upload} alt="upload" width={64} height={64} /></button>
                    <span>Drag and drop your file here or</span>
                    Upload a file
                </div>
            </form>
            
            }
            {((fileinfo.name || fileinfo.url) && !loading)&& 
            <div id='file-detail'>
                <div>
                    <img src={folder} alt="file" />
                    <span>{fileinfo.name ?? fileinfo.url}</span>
                </div>
                <button className="withnobg" onClick={()=>removeState()}><img src={dustbin} alt="file" /></button>
            </div>  }
        </>
    );
};

export default DragDropFile;