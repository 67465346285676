import React from 'react'
import logo from '../assets/icons/logo.svg'
import dropdown from '../assets/icons/dropdown.svg'
import calender from '../assets/icons/calender.svg'
import archieve from '../assets/icons/archieve.svg'

const Cards = ({ heading, transactions }) => {
    let totalSum = 0;
    let successCount = 0;
    let failCount = 0;
    let pendingCount = 0;
    let failSum = 0;
    let successSum = 0;
    let pendingSum = 0;
    let totalCount = transactions?.length || 0;
    transactions?.forEach((e) => {
        if (e.status === 'success') {
            successSum = successSum + parseFloat(e.amount);
            successCount++;
        }
        if (e.status === 'pending') {
            pendingSum = pendingSum + parseFloat(e.amount);
            pendingCount++;
        }
        if (e.status === 'failed') {
            failSum = failSum + parseFloat(e.amount);
            failCount++;
        }
        totalSum += parseFloat(e.amount);
    });
    return (
        <>
            <div id='head'>
                <h2>{heading}</h2>
                {/* <div>
                    <div id='date-selector' className='dropdown'>
                        <div className='dropdown-select'>Past 7 days <img src={dropdown} alt="dropdown" /></div>
                        <div id='calender'><img src={calender} alt="calender" /></div>
                    </div>
                    <div id='archieve'><img src={archieve} alt="archieve" /></div>
                </div> */}
            </div>
            <div className='head-cards-container'>
                <div className='head-card'>
                    <p>
                        <div><img src={logo} alt="BRISE" width={18} height={18} /></div>
                        <span>All Transactions</span>
                    </p>
                    <div>
                        <div>
                            <span style={{ fontWeight: '600' }}>{totalCount}</span>
                            <span style={{ fontSize: '10px' }}>Txn Count</span>
                        </div>
                        <p><span style={{ fontSize: '18px', fontWeight: '600' }}>{totalSum}</span> (BRISE)</p>
                    </div>
                </div>
                <div className='head-card'>
                    <p>
                        <div><img src={logo} alt="BRISE" width={18} height={18} /></div>
                        <span>Success Transactions</span>
                    </p>
                    <div>
                        <div>
                            <span style={{ fontWeight: '600' }}>{successCount}</span>
                            <span style={{ fontSize: '10px' }}>Success Count</span>
                        </div>
                        <p><span style={{ fontSize: '18px', fontWeight: '600' }}>{successSum}</span> (BRISE)</p>
                    </div>
                </div>
                <div className='head-card'>
                    <p>
                        <div><img src={logo} alt="BRISE" width={18} height={18} /></div>
                        <span>Pending Transactions</span>
                    </p>
                    <div>
                        <div>
                            <span style={{ fontWeight: '600' }}>{pendingCount}</span>
                            <span style={{ fontSize: '10px' }}>Pending Count</span>
                        </div>
                        <p><span style={{ fontSize: '18px', fontWeight: '600' }}>{pendingSum}</span> (BRISE)</p>
                    </div>
                </div>
                <div className='head-card'>
                    <p>
                        <div><img src={logo} alt="BRISE" width={18} height={18} /></div>
                        <span>Fail Transactions</span>
                    </p>
                    <div>
                        <div>
                            <span style={{ fontWeight: '600' }}>{failCount}</span>
                            <span style={{ fontSize: '10px' }}>Pending Count</span>
                        </div>
                        <p><span style={{ fontSize: '18px', fontWeight: '600' }}>{failSum}</span> (BRISE)</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards