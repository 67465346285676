
import React , {useState ,useEffect} from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../store/slices/messageSlice";
const Alert = () =>{
     const {type,message,position} = useSelector((state)=>state.message);
     const dispatch = useDispatch();
     if(type == 'error' && message != '')
     {
        toast.error(message);
        
        dispatch(clearMessage());
     }
     if(type == 'success' && message != '')
     {
        toast.success(message)
        dispatch(clearMessage());
     }
     
    return <><Toaster position='top-right'/></>
}

export default Alert;